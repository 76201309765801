import React, { useMemo } from 'react'
import { useAuth } from 'src/modules/Auth/Core'
import { Task } from '../data/schema'
import { ROLES } from 'src/helpers'
import { Badge } from 'src/shadcn/components/ui'

type Props = {
    row: any
}

const ProjectStatus = ({ row }: Props) => {

    const { currentUser } = useAuth()

    const status: "approved" | "rejected" | "pending" = useMemo(() => {

        const findCurrentStakHolder = row.status
        if (findCurrentStakHolder) {
            if (findCurrentStakHolder.toLowerCase() == "approved" || findCurrentStakHolder.toLowerCase() == "Yes") {
                return "approved"
            }
            else if (findCurrentStakHolder.toLowerCase() == "reject" || findCurrentStakHolder.toLowerCase() == "No") {
                return "rejected"
            }
        }

        return "pending"
    }, [currentUser, row, ROLES])


    return (
        <div>
            {status == 'approved' ? <Badge variant={'outline_success'}>Approved</Badge> : null}
            {status == 'rejected' ? <Badge variant={'outline_destructive'}>Rejected</Badge> : null}
            {status == 'pending' ? <Badge variant={'secondary'}>Pending</Badge> : null}
        </div>
    )
}

export default ProjectStatus