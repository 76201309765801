import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { catchAsync, ROLES } from 'src/helpers';
import { useAuth } from 'src/modules/Auth/Core';
import AdminDashboardSummary from 'src/modules/Dashboards/AdminDashboardSummary';
import StackholderDashboardSummary from 'src/modules/Dashboards/StackholderDashboardSummary';
import { APP_ROUTES, EPC_CONTRACTORS_ROUTES } from 'src/navigations/modules';
import { epcViewProfileByIDApiRequest } from 'src/services/requests/EPC/profile';
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area';
import AdminProjectHistory from './AdminProjectHistory';
import EPCProjectHistory from './EPCProjectHistory';
import EPCContractorDashboardSummary from 'src/modules/Dashboards/EPCContractorDashboardSummary';

type Props = {}

const DashboardMain = (props: Props) => {

  const { currentUser } = useAuth()



  console.log({role: currentUser?.role});
  


  return (
    <div className='p-4 space-y-2'>
      <ScrollArea className='h-[85vh]'>
        {currentUser?.role === ROLES.ADMIN_USER ? <AdminDashboardSummary /> : null}
        {/* {currentUser?.role == ROLES.EVALUATOR ? <EvaluatorDashboardSummary /> : null} */}
        {currentUser?.role === "EPC_CONTRACTOR" ? <EPCContractorDashboardSummary /> : null}
        <div className=''>
          {/* <InvestmentAnalysis /> */}

          <div>
            {currentUser?.role === ROLES.ADMIN_USER ? <AdminProjectHistory /> : null}
            {currentUser?.role === "EPC_CONTRACTOR" ? <EPCProjectHistory /> : null}
          </div>

        </div>
      </ScrollArea>
    </div>
  )
}

export default DashboardMain