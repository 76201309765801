import { AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { catchAsync, successTheme } from 'src/helpers'
import { MAIN_ROUTES } from 'src/navigations/modules'
import { registrationRequestApi } from 'src/services/requests/Auth'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { REGISTRATION_FORM_FIELDS, registration_initialValues, registrationFormValidationSchema } from './modules'
import { toast } from 'src/shadcn/components/ui/use-toast'
import { toast as sonarToast } from 'sonner'

type Props = {}

const SignUp = (props: Props) => {

    const [isLoading, setisLoading] = useState(false)
    const navigate = useNavigate()


    const formik = useFormik({
        initialValues: registration_initialValues,
        validationSchema: registrationFormValidationSchema,
        onSubmit: (values, { setSubmitting }) => {
            // Handle form submission here
            handleRegistrationForm()
        },
    });


    console.log({ error: formik.errors, touch: formik.touched });


    const handleRegistrationForm = catchAsync(
        async () => {
            setisLoading(true)
            return await registrationRequestApi(formik.values);
        },
        (result: AxiosResponse) => {
            if (result.status == 201) {
                sonarToast.success("Registration successful, You can login now.", { style: successTheme })
                formik.resetForm()
                setisLoading(false)
                navigate(MAIN_ROUTES.auth_login)
            }
            console.log('Registration successful:', result);
        }, () => {
            setisLoading(false)
        }
    );

    
    const handleFormSubmit = () => {
        if (formik.isValid) {
            formik.handleSubmit()
        }
        else {
            Object.entries(formik.errors).map(([key, value]) => {
                formik.setFieldError(key, value)
                formik.setFieldTouched(key, true)
            })
            toast({
                variant: "destructive",
                title: "Form Validation Failed",
                description: "Please enter valid data"
            })
        }
    }


    return (
        <div className='mx-16 space-y-2'>
            <div className='space-y-2'>
                <div className='uppercase text-xl font-bold text-center text-primary'>Admin Signup </div>
                <div className=' text-center text-sm'>Please enter your email address and your password. </div>
            </div>
            <div className='pb-4'>
                <form className='space-y-2' onSubmit={(e:any)=>{
                    e.preventDefault()
                    handleFormSubmit()
                }}>
                    <div className='space-y-2'>
                        {
                            REGISTRATION_FORM_FIELDS.map((field: any) => {
                                return <RenderFormComponents key={`login-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
                            })
                        }
                    </div>
                    <div><RoundedButton isLoading={isLoading} type='submit' className='w-full'>Sign Up</RoundedButton></div>
                </form>
            </div>
        </div>
    )
}

export default SignUp