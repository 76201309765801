import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { initSalesExecutive } from 'src/modules/EpcContractors/SalesExecutives/modules'
import SalesExecutiveForm from 'src/modules/EpcContractors/SalesExecutives/SalesExecutiveForm'
import { EPC_CONTRACTORS_ROUTES } from 'src/navigations/modules'
import { ApiResponse } from 'src/services/consts/api_const'
import { updateEpcSalesRepresentativeByIDApiRequest, ViewEpcSalesRepresentativeByIDApiRequest } from 'src/services/requests/EPC/sales_representative'

type Props = {}

const EpcContractorAgnetsEditMain = (props: Props) => {
  const [formData, setformData] = useState(initSalesExecutive)
  const [isLoading, setisLoading] = useState(false)
  const { id } = useParams()
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!id) navigate(-1)
    else handleFetchSalesExecutiveById()
    return () => { }
  }, [id])


  const handleUpdateSalesExecutive = catchAsync(async (frmData: any) => {
    setisLoading(true)
    return await frmData && currentUser && id && updateEpcSalesRepresentativeByIDApiRequest(id, {
      ...frmData,
      epc_contractors_id: currentUser.id
    })
  }, (result: ApiResponse) => {
    console.log({ result })
    if (result.status == 200) {
      navigate("/" + EPC_CONTRACTORS_ROUTES.sales_executive.subRoutes.all.fullRoute)
    }
    setisLoading(false)
  }, () => {
    setisLoading(false)
  })

  const handleFetchSalesExecutiveById = catchAsync(async () => {
    if (id) {
      setisLoading(true)
      return await ViewEpcSalesRepresentativeByIDApiRequest(id)
    }
  }, (result: ApiResponse) => {
    console.log({ result })
    if (result.status == 200) {
      setformData(result.data)
    }
    setisLoading(false)
  }, () => {
    setisLoading(false)
  })

  return (
    <div>
      <SalesExecutiveForm initial_values={formData} submitBtnTitle='Submit' isLoadingForm={isLoading} onSubmit={(e: any) => handleUpdateSalesExecutive(e)} />
    </div>
  )
}

export default EpcContractorAgnetsEditMain