import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { catchAsync, successTheme } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import EPCProfileForm from 'src/modules/EpcContractors/EpcProfile/EPCProfileForm'
import { INIT_EPC_CONTRACTOR_PROFILE } from 'src/modules/EpcContractors/EpcProfile/modules'
import ServicesFrm from 'src/modules/EpcContractors/EpcProfile/ServicesFrm'
import { EPC_CONTRACTORS_ROUTES } from 'src/navigations/modules'
import { epcCreateProfileApiRequest, epcUpdateProfileApiRequest, epcViewProfileByIDApiRequest } from 'src/services/requests/EPC/profile'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Separator, Tabs, TabsContent, TabsList, TabsTrigger } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'

type Props = {}

const EpcContractorUpdateMain = (props: Props) => {

    const [isLoading, setisLoading] = useState<boolean>(false)
    const [initFormDate, setinitFormDate] = useState(INIT_EPC_CONTRACTOR_PROFILE)
    const [resultData, setresultData] = useState<any>()
    const [profileData, setprofileData] = useState<any>()
    const [ServicesData, setServicesData] = useState<any>()
    const [currentActiveTab, setcurrentActiveTab] = useState<string>("complete_profile")
    const navigate = useNavigate()
    const { currentUser } = useAuth()

    const { id } = useParams()

    useEffect(() => {
        id && handleFetchUserProfile()
        return () => {
        }
    }, [id])


    // useEffect(() => {
    //     if (currentUser?.email) {
    //         setinitFormDate({
    //             ...initFormDate,
    //             contractorEmail: currentUser?.email
    //         })
    //     }
    //     return () => { }
    // }, [])


    const handleFetchUserProfile = catchAsync(async () => {
        setisLoading(true)
        return id && await epcViewProfileByIDApiRequest(id)
    }, (result: any) => {
        console.log({ result })
        setprofileData(result?.data);
        setServicesData(result?.data?.services);
        setresultData({
            _id: result?.data?._id,
            epc_id:result?.data?.epc_contractor_id ,
        })
        setcurrentActiveTab("complete_profile")
        setisLoading(false)

    }, () => { setisLoading(false) })

    const handleUpdateProfile = catchAsync(async (formdata: any) => {
        setisLoading(true)
        // console.log({ formdata });
        return formdata && await epcUpdateProfileApiRequest({
            ...formdata,
            services: profileData?.services,
            _id: profileData?._id
        })
    }, (result: any) => {
        if (result.status == 201 || result.status == 200) {
            toast.success("Profile Updated.", { style: successTheme })
        }
        setisLoading(false)
    }, () => {
        setisLoading(false)
    })

    console.log({ initFormDate });

    return (
        <div className='p-4'>
            <ScrollArea className='h-[90vh]'>

                <Tabs value={currentActiveTab} onValueChange={(e: any) => setcurrentActiveTab(e)} className="">
                    <TabsList>
                        <TabsTrigger value="complete_profile">Profile</TabsTrigger>
                        <TabsTrigger
                            value="add_services">Services</TabsTrigger>
                    </TabsList>
                    <TabsContent value="complete_profile">
                        <EPCProfileForm disableForm={false} initial_values={profileData} submitBtnTitle='Update' onSubmit={(formdata: any) => handleUpdateProfile(formdata)} />
                    </TabsContent>
                    <TabsContent value="add_services">
                        <ServicesFrm id={profileData?._id} epc_id={profileData?.epc_contractor_id} />
                    </TabsContent>
                </Tabs>

                <Separator />
                {/* <div className='uppercase text-md font-bold text-primary mt-4 '>Services </div> */}

                {/* <div className='flex justify-center space-x-2 my-2'>
                    <RoundedButton type='button' disabled={false} variant={'secondary'} onClick={() => navigate(-1)} >Back</RoundedButton>
                    <RoundedButton onClick={() => navigate("/" + EPC_CONTRACTORS_ROUTES.profile.subRoutes.view.fullRoute + "/" + currentUser?.id)} >Save</RoundedButton>
                </div> */}
            </ScrollArea>
            <LoadingDialog isOpen={isLoading} message="Profile updating..." />
        </div>
    )
}

export default EpcContractorUpdateMain