import { Eye, Pencil } from 'lucide-react'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROLES } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { APP_ROUTES } from 'src/navigations/modules'
import ToolTip from 'src/shadcn/components/custom/ToolTip'
import { Button, Tooltip } from 'src/shadcn/components/ui'

type Props = {
    row: any
}

const Actions = ({ row }: Props) => {

    const navigate = useNavigate()
    const { currentUser } = useAuth()

    const status: "approved" | "rejected" | "pending" = useMemo(() => {
       if (currentUser && currentUser?.role && currentUser.role == ROLES.STAKEHOLDER) {
            const findCurrentStakHolder = row.stakeholders_details?.find((s: any) => s.userid == currentUser.id)?.evaluation_status
            if (findCurrentStakHolder) {
                if (findCurrentStakHolder == "approved" || findCurrentStakHolder == "Yes" ) {
                    return "approved"
                }
                else if (findCurrentStakHolder == "rejected" || findCurrentStakHolder == "No") {
                    return "rejected"
                }
            }
        }
        return "pending"
    }, [currentUser, row, ROLES])

    return (
        <div className='flex space-x-2'>
            {
                currentUser?.role && [ ROLES.ADMIN_USER].includes(currentUser.role) ?
                    <ToolTip tooltipContent='Edit project'>
                        <Button disabled={status != "pending"} size={"sm"} variant={'primaryIconBtn'} onClick={() => navigate("/" + APP_ROUTES.projects.subRoutes.edit.fullRoute + "/" + row._id)} >
                            <Pencil size="16" />
                        </Button>
                    </ToolTip>
                    : null
            }
            <ToolTip  tooltipContent='View project'>
                <Button size={"sm"} variant={'primaryIconBtn'} onClick={() => navigate("/" + APP_ROUTES.projects.subRoutes.view.fullRoute + "/" + row._id)} >
                    <Eye size="16" />
                </Button>
            </ToolTip>
        </div>
    )
}

export default Actions