import React, { useEffect, useState } from 'react'
import { DataTable } from '../SalesExecutives/Table/components/data-table'
import { columns } from '../SalesExecutives/Table/components/columns'
import { useAuth } from 'src/modules/Auth/Core'
import { catchAsync } from 'src/helpers'
import { allEpcSalesRepresentativeByIDApiRequest } from 'src/services/requests/EPC/sales_representative'
import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'

type Props = {}

const AllSalesExecutives = (props: Props) => {


    const [tableData, settableData] = useState<any[]>([])
    const { id } = useParams()

    useEffect(() => {
        handle_get_all_projects()
        return () => { }
    }, [])


    const handle_get_all_projects = catchAsync(
        async () => {
            return await allEpcSalesRepresentativeByIDApiRequest()
        },
        (result: AxiosResponse) => {
            console.log({allSalesRepresresentative : result?.data});
            
            if (result.data && Array.isArray(result.data)) {
                let extractSalesRepresentatives =result.data.filter((d: any) => d?.epc_contractors_id == id).map((d: any, indx: number) => {
                    return {
                        srNo: indx + 1,
                        ...d,
                    }
                })
                settableData(extractSalesRepresentatives)
                console.log({extractSalesRepresentatives : extractSalesRepresentatives});
            }
            
        }
    );



    return (
        <div>
            <DataTable data={tableData || []} columns={columns} />
        </div>
    )
}

export default AllSalesExecutives