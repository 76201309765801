import React, { useEffect, useMemo, useState } from 'react'
import { Task } from '../data/schema'
import { Button } from 'src/shadcn/components/ui'
import { Link } from 'react-router-dom'
import { catchAsync, ROLES } from 'src/helpers'
import { generateAgreementApiRequest, updateProjectsDetailsByIDApiRequest } from 'src/services/requests/Projects'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import { useAuth } from 'src/modules/Auth/Core'

type Props = {
    row: Task
}

const Agreement = ({ row }: Props) => {

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showAgreementBtn, setshowAgreementBtn] = useState<boolean>(false)
    const [agrreMentUrl, setagrreMentUrl] = useState<any>(null)
    const { currentUser } = useAuth()

    useEffect(() => {
        if (row.agreementUrl && typeof row.agreementUrl == "string") {
            setshowAgreementBtn(true)
        }
        return () => { }
    }, [row.agreementUrl])


    const handleGenerateProjectAgeement = catchAsync(async () => {
        setIsLoading(true)
        return await generateAgreementApiRequest(row._id)
    }, (result: any) => {
        setIsLoading(false)
        setagrreMentUrl(result.s3Url)
        result.s3Url && handleUPdateProjectAgeement(result.s3Url)
    }, () => {
        setIsLoading(false)
    })

    const handleUPdateProjectAgeement = catchAsync(async (url: string) => {
        setIsLoading(true)
        return await updateProjectsDetailsByIDApiRequest(row._id, { agreementUrl: url })
    }, (result: any) => {
        if (result.status == 200) {
            setshowAgreementBtn(true)
        }
        setIsLoading(false)
    }, () => {
        setIsLoading(false)
    })


    const showGenerateBtn: Boolean = useMemo(() => {
        if (!row?.agreementUrl && currentUser?.role == ROLES.ADMIN_USER && row.status == "Approved" && !showAgreementBtn) {
            return true
        }
        return false
    }, [showAgreementBtn, row?.agreementUrl])


    const showViewBtn: Boolean = useMemo(() => {
        if (row?.agreementUrl && row.status != "New") {
            return true
        }
        else if (showAgreementBtn) {
            return true
        }
        return false
    }, [showAgreementBtn, row?.agreementUrl])

    return (
        <div>
            {showGenerateBtn ? <Button disabled={isLoading} variant={isLoading ? "secondary" : "outline"} onClick={() => handleGenerateProjectAgeement()} className='h-7 rounded-lm' size={'sm'} >{isLoading ? "Generating..." : "Generate"}</Button> : null}
            {showViewBtn ? <Link to={row?.agreementUrl || agrreMentUrl} target='_blank' ><span className='h-7 text-sm text-blue-600 underline underline-offset-2 ' >View</span></Link> : null}
        </div >
    )
}

export default Agreement