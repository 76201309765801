import { Plus } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { ROLES } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { APP_ROUTES } from 'src/navigations/modules'
import { Button } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import AllEpcContractorsTable from './AllProjects'

type Props = {}

const AllEpcContractorsMain = (props: Props) => {

  const navigate = useNavigate()
  const { currentUser } = useAuth()

  return (
    <div className='m-4 space-y-2 '>
      {/* {
        currentUser?.role == ROLES.ADMIN_USER && <div className='flex justify-end'>
          <Button onClick={() => navigate("/" + APP_ROUTES.projects.subRoutes.create.fullRoute)} size={"sm"} className='space-x-2'><span><Plus size={16} /></span><span>Create Project</span> </Button>
        </div>
      } */}
      <div className='flex justify-between'>
        <span className='text-primary'>All EPC Contractors</span>
        <Button onClick={() => navigate("/" + APP_ROUTES.epc_contractors.subRoutes.create.fullRoute)} className='space-x-1'><span><Plus size={16} /></span> <span>Create </span> </Button>
      </div>
      <AllEpcContractorsTable />
    </div >
  )
}

export default AllEpcContractorsMain