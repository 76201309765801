import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { catchAsync, EXTRACT_NUMBERS_REGEX, FormField, NUMBER_REGEX } from 'src/helpers'
import { PROJECT_FORM_FIELDS, PROJECT_FORM_INITIAL_VALUES } from 'src/modules/Projects/modules'
import ProjectForm from 'src/modules/Projects/ProjectForm'
import { APP_ROUTES } from 'src/navigations/modules'
import { getProjectsDetailsByIDApiRequest, updateProjectsDetailsByIDApiRequest } from 'src/services/requests/Projects'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'

type Props = {}

const EditProjectMain = (props: Props) => {

  const { id } = useParams()
  const navigate = useNavigate()
  const [projectDetails, setprojectDetails] = useState(PROJECT_FORM_INITIAL_VALUES)

  const [isLoading, setisLoading] = useState<{
    status: boolean,
    message: string
  }>({
    status: false,
    message: ""
  })

  useEffect(() => {
    if (id) handleFetchProjectDetails()
    else navigate("/" + APP_ROUTES.projects.subRoutes.all.fullRoute)
    return () => { }
  }, [])

  const handleFetchProjectDetails = catchAsync(async () => {
    setisLoading({
      status: true,
      message: "Fetching Project"
    })
    return id && await getProjectsDetailsByIDApiRequest(id)
  }, (result: AxiosResponse) => {
    console.log({ getProjectDetailsByID: result });
    setisLoading({
      status: false,
      message: ""
    })
    setprojectDetails({ ...result.data, pricePerKwh: result.data?.pricePerKwh })
  }, () => {
    setisLoading({
      status: false,
      message: ""
    })
  })


  const handleUpdateProject = catchAsync(async (formData: any) => {
    setisLoading({
      status: true,
      message: "Updating Project"
    })
    let postData: any = {};
    Object.entries(formData).forEach(([key, value]) => {
      let filed: FormField | undefined = PROJECT_FORM_FIELDS.find((d: FormField) => d.dataKey == key)
      if (filed && filed.componentProps?.numberFormatted) {
        postData[key] = value && value.toString().replace(EXTRACT_NUMBERS_REGEX, "")
      }
      else postData[key] = value
    });
    return id && await updateProjectsDetailsByIDApiRequest(id, postData)
  }, (result: AxiosResponse) => {
    console.log({ result });
    if (result?.status == 200) {
      toast.success("Project updated..")
      setisLoading({
        status: false,
        message: ""
      })
      navigate(-1)
    }
  }, () => {
    setisLoading({
      status: false,
      message: ""
    })
  })

  return (
    <div>
      <ProjectForm initial_values={projectDetails} isLoadingForm={isLoading.status} submitBtnTitle={"Submit"} onSubmit={(formData: any) => handleUpdateProject(formData)} />
      <LoadingDialog isOpen={isLoading.status} message={isLoading.message} />
    </div>
  )
}

export default EditProjectMain