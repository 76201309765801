import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import EPCProfileReview from 'src/modules/EpcContractors/EpcProfile/EPCProfileReview'
import { INIT_EPC_CONTRACTOR_PROFILE, INIT_EPC_SERVICES } from 'src/modules/EpcContractors/EpcProfile/modules'
import ManufacturerProfileReview from 'src/modules/Manufacturer/ManufacturerProfile/ManufacturerProfileReview'
import { APP_ROUTES, EPC_CONTRACTORS_ROUTES } from 'src/navigations/modules'
import { epcViewProfileByIDApiRequest } from 'src/services/requests/EPC/profile'
import { getDetailsManufacturerProfileByIDApiRequest } from 'src/services/requests/Manufacturers'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const DATA = {
  "contractorName": "John Doe",
  "contractorNumber": "1234567890",
  "contractorEmail": "johndoe@example.com",
  "location": "New York",
  "address": "123 Main St",
  "pincode": "10001",
  "capacityInstalled": "1000 kW",
  "noOfYearsExperiance": "5",
  "noOfReview": "10",
  "freeAreaAvailableOnGround": "1000 sqft",
  "password": "password123",
  "services": [
    {
      "productImage": "https://example.com/product1.jpg",
      "productName": "Product 1",
      "productDescription": "This is product 1"
    },
    {
      "productImage": "https://example.com/product2.jpg",
      "productName": "Product 2",
      "productDescription": "This is product 2"
    }
  ],
  "projectDescription": "This is a sample project",
  "companyLogo": "https://example.com/company-logo.jpg"
}

const ManufacturerViewMain = (props: Props) => {

  const { currentUser } = useAuth()
  const { id } = useParams()
  const navigate = useNavigate()
  const [profileDetails, setprofileDetails] = useState<any>()
  const [isLoading, setisLoading] = useState<any>()


  useEffect(() => {
    if (id) {
      handleFetchUserProfileDetails()
    }
    else navigate(-1)
    return () => { }
  }, [id])


  const handleFetchUserProfileDetails = catchAsync(async () => {
    setisLoading(true)
    return id && await getDetailsManufacturerProfileByIDApiRequest(id)
  }, (result: any) => {
    if (result.status == 200) {
      if (result.data) {
        setprofileDetails(result.data)
      }
      else {
        navigate("/" + EPC_CONTRACTORS_ROUTES.profile.subRoutes.create.fullRoute)
      }

      setisLoading(false)
    }
  }, () => { setisLoading(false) })

  return (
    <div>
      <ManufacturerProfileReview data={profileDetails || INIT_EPC_CONTRACTOR_PROFILE} />
      <div className='flex justify-center my-2'>
        <RoundedButton variant={'outline'} onClick={() => navigate(-1)}>Back</RoundedButton>
        <RoundedButton onClick={() => navigate("/" + APP_ROUTES.manufacturers.subRoutes.edit.fullRoute + "/" + id)}>Update Profile</RoundedButton>
      </div>
      <LoadingDialog isOpen={isLoading} message='Fetching user details' />
    </div>
  )
}

export default ManufacturerViewMain