import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { initSalesExecutive } from 'src/modules/EpcContractors/SalesExecutives/modules'
import SalesExectiveReviewForm from 'src/modules/EpcContractors/SalesExecutives/SalesExectiveReviewForm'
import { ApiResponse } from 'src/services/consts/api_const'
import { ViewEpcSalesRepresentativeByIDApiRequest } from 'src/services/requests/EPC/sales_representative'

import RoundedButton from 'src/shadcn/components/custom/RoundedButton'


type Props = {}

const EpcContractorAgnetsViewMain = (props: Props) => {

  const [formData, setformData] = useState(initSalesExecutive)
  const [isLoading, setisLoading] = useState(false)
  const { id } = useParams()
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!id) navigate(-1)
    else handleFetchSalesExecutiveById()
    return () => { }
  }, [id])

  const handleFetchSalesExecutiveById = catchAsync(async () => {
    if (id) {
      setisLoading(true)
      return await ViewEpcSalesRepresentativeByIDApiRequest(id)
    }
  }, (result: ApiResponse) => {
    console.log({ result })
    if (result.status == 200) {
      setformData(result.data)
    }
    setisLoading(false)
  }, () => {
    setisLoading(false)
  })

  return (
    <div>
      <SalesExectiveReviewForm data={formData} />
      <div  className='flex justify-center p-4'>
        <RoundedButton  onClick={() => navigate(-1)} >Back</RoundedButton>
      </div>
    </div>
  )
}

export default EpcContractorAgnetsViewMain