import { Pencil } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { catchAsync } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import EPCProfileReview from 'src/modules/EpcContractors/EpcProfile/EPCProfileReview'
import { INIT_EPC_CONTRACTOR_PROFILE, INIT_EPC_SERVICES } from 'src/modules/EpcContractors/EpcProfile/modules'
import { EPC_CONTRACTORS_ROUTES } from 'src/navigations/modules'
import { epcViewProfileByIDApiRequest } from 'src/services/requests/EPC/profile'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const DATA = {
  "contractorName": "John Doe",
  "contractorNumber": "1234567890",
  "contractorEmail": "johndoe@example.com",
  "location": "New York",
  "address": "123 Main St",
  "pincode": "10001",
  "capacityInstalled": "1000 kW",
  "noOfYearsExperiance": "5",
  "noOfReview": "10",
  "freeAreaAvailableOnGround": "1000 sqft",
  "password": "password123",
  "services": [
    {
      "productImage": "https://example.com/product1.jpg",
      "productName": "Product 1",
      "productDescription": "This is product 1"
    },
    {
      "productImage": "https://example.com/product2.jpg",
      "productName": "Product 2",
      "productDescription": "This is product 2"
    }
  ],
  "projectDescription": "This is a sample project",
  "companyLogo": "https://example.com/company-logo.jpg"
}

const EpcContractorViewMain = (props: Props) => {

  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const [profileDetails, setprofileDetails] = useState<any>()
  const [isLoading, setisLoading] = useState<any>()


  useEffect(() => {
    if (currentUser?.id) {
      handleFetchUserProfileDetails()
    }
    else navigate(-1)
    return () => { }
  }, [currentUser?.id])


  const handleFetchUserProfileDetails = catchAsync(async () => {
    setisLoading(true)
    return currentUser?.id && await epcViewProfileByIDApiRequest(currentUser?.id)
  }, (result: any) => {
    if (result.status == 200) {
      if (result.data) {
        setprofileDetails(result.data)
      }
      else {
        navigate("/" + EPC_CONTRACTORS_ROUTES.profile.subRoutes.create.fullRoute)
      }

      setisLoading(false)
    }
  }, () => { setisLoading(false) })

  return (
    <div>
      <div className='px-4 py-2 flex justify-between'>
        <div className='text-md uppercase text-primary font-semibold'>Profile Details</div>
        <button onClick={() => navigate("/" + EPC_CONTRACTORS_ROUTES.profile.subRoutes.edit.fullRoute + "/" + currentUser?.id)}><div className='flex items-center space-x-2 underline underline-offset-2 text-primary/75 hover:font-semibold hover:text-primary '>
          <span><Pencil size={16} /></span>
          <span>Edit Profile</span>
        </div></button>
        {/* <RoundedButton>Update Profile</RoundedButton> */}
      </div>
      <EPCProfileReview data={profileDetails || INIT_EPC_CONTRACTOR_PROFILE} />
      <div className='flex justify-center py-4 space-x-2 '>
        <RoundedButton variant={'outline'}> Back</RoundedButton>
        {/* <RoundedButton onClick={()=> navigate("/" + EPC_CONTRACTORS_ROUTES.profile.subRoutes.edit.fullRoute + "/" + currentUser?.id) } > Update Profile</RoundedButton> */}
      </div>
      <LoadingDialog isOpen={isLoading} message='Fetching user details' />
    </div>
  )
}

export default EpcContractorViewMain