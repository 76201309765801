import React from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { EPC_CONTRACTORS_ROUTES } from 'src/navigations/modules'
import EpcContractorAgnetsMain from './EpcContractorAgnetsMain'
import EpcContractorAgnetsCreateMain from './EpcContractorAgnetsCreateMain'
import EpcContractorAgnetsViewMain from './EpcContractorAgnetsViewMain'
import EpcContractorAgnetsEditMain from './EpcContractorAgnetsEditMain'

type Props = {}

const EPCAgentsRoutes = (props: Props) => {
    const PROJECTS_ROUTES = EPC_CONTRACTORS_ROUTES.sales_executive.subRoutes

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<EpcContractorAgnetsMain />} />
                <Route path={PROJECTS_ROUTES.all.route} element={<EpcContractorAgnetsMain />} />
                <Route path={PROJECTS_ROUTES.create.route} element={<EpcContractorAgnetsCreateMain />} />
                <Route path={PROJECTS_ROUTES.view.route + "/:id"} element={<EpcContractorAgnetsViewMain />} />
                <Route path={PROJECTS_ROUTES.edit.route + "/:id"} element={<EpcContractorAgnetsEditMain />} />
                <Route path={"*"} element={<Navigate to={"/" + PROJECTS_ROUTES.all.route} />} />
            </Route>
        </Routes>
    )
}

export default EPCAgentsRoutes